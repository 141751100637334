import React from "react"

import "./Button.scss"

export default function Button({ children, variant, type }) {
  return (
    <button className={`btn ${variant}`} type={type}>
      {children}
    </button>
  )
}
