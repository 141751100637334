import React, { useState } from "react"
import axios from "axios"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"

import Section from "../../Section/Section"
import Button from "../../Button/Button"

import "./Contact.scss"

export default function Contact() {
  const { t } = useTranslation()

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const { register, errors, handleSubmit, reset } = useForm()

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      reset()
    }
  }

  const handleOnSubmit = data => {
    const form = new FormData()
    form.append("first_name", data.first_name)
    form.append("last_name", data.last_name)
    form.append("email", data.email)
    form.append("phone", data.phone)
    form.append("company", data.company)
    form.append("message", data.message)

    setServerState({ submitting: true })
    axios({
      method: "post",
      url: "https://getform.io/f/02605545-bd4a-4e9d-8021-15419e826a89",
      data: form,
    })
      .then(r => {
        handleServerResponse(
          true,
          "Thank you! Someone will contact you shortly!",
          form
        )
      })
      .catch(r => {
        console.log(r)
        handleServerResponse(false, r?.response?.data?.error, form)
      })
  }

  return (
    <div className="contact text-left">
      <Section>
        <h2 id="contact">{t("Contact.title")}</h2>
        <p>{t("Contact.subtitle")}</p>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="form-group">
            <label aria-label="First name" htmlFor="first_name">
              First Name
            </label>
            <input
              type="text"
              placeholder={t("Contact.first_name")}
              name="first_name"
              id="first_name"
              ref={register({
                required: true,
                pattern: {
                  value: /^[\u0621-\u064Aa-zA-Z ']{1,}$/,
                },
              })}
            ></input>
            {errors?.first_name?.type === "required" && (
              <p className="error_text">{t("Contact.firstNameRequired")}</p>
            )}
            {errors?.first_name?.type === "pattern" && (
              <p className="error_text">{t("Contact.firstNameValidity")}</p>
            )}
            <label aria-label="Last name" htmlFor="last_name">
              Last Name
            </label>
            <input
              type="text"
              placeholder={t("Contact.last_name")}
              name="last_name"
              id="last_name"
              ref={register({
                required: true,
                pattern: {
                  value: /^[\u0621-\u064Aa-zA-Z ']{1,}$/,
                },
              })}
            ></input>
            {errors?.last_name?.type === "required" && (
              <p className="error_text">{t("Contact.lastNameRequired")}</p>
            )}
            {errors?.last_name?.type === "pattern" && (
              <p className="error_text">{t("Contact.lastNameValidity")}</p>
            )}
          </div>
          <label aria-label="email" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            placeholder={t("Contact.email")}
            name="email"
            id="email"
            ref={register({ required: true })}
          ></input>
          {errors?.email?.type === "required" && (
            <p className="error_text">{t("Contact.emailRequired")}</p>
          )}
          <label aria-label="Phone number" htmlFor="phone">
            Phone
          </label>
          <input
            type="text"
            placeholder={t("Contact.phone")}
            name="phone"
            id="phone"
            ref={register({
              required: true,
            })}
          ></input>
          {errors?.phone?.type === "required" && (
            <p className="error_text">{t("Contact.phoneRequired")}</p>
          )}
          <label aria-label="Company name" htmlFor="company">
            Company
          </label>
          <input
            type="text"
            placeholder={t("Contact.company")}
            name="company"
            id="company"
            ref={register({
              pattern: {
                value: /^[\u0621-\u064Aa-zA-Z ']{1,}$/,
              },
            })}
          ></input>
          {errors?.company?.type === "pattern" && (
            <p className="error_text">{t("Contact.companyValidity")}</p>
          )}
          <label aria-label="Message" htmlFor="message">
            Message
          </label>
          <input
            type="textarea"
            placeholder={t("Contact.msg")}
            name="message"
            id="message"
            ref={register({
              required: true,
              pattern: {
                value: /^[\u0621-\u064Aa-zA-Z ']{1,}$/,
              },
            })}
          ></input>
          {errors?.message?.type === "required" && (
            <p className="error_text">{t("Contact.msgRequired")}</p>
          )}
          {errors?.message?.type === "pattern" && (
            <p className="error_text">{t("Contact.msgValidity")}</p>
          )}
          <Button
            type="submit"
            className="btn btn-primary mb-4 mt-4"
            variant="primary"
          >
            {t("Contact.submit")}
          </Button>
          <div className="col s9">
            {serverState.status && (
              <p className={!serverState.status.ok ? "errorMsg" : ""}>
                {serverState.status.msg}
              </p>
            )}
          </div>
        </form>
        <div className="icons-container">
          <a href="mailto:info@dayra.co">
            <div className="mail-container">
              <div className="icon-container mail">
                <StaticImage
                  width={10}
                  height={10}
                  placeholder="none"
                  src="../../../images/envelope.svg"
                  className="icon"
                  alt="Email"
                ></StaticImage>
              </div>
              <p className="mail-text">info@dayra.co</p>
            </div>
          </a>
          <div className="social-media-container">
            <a href="https://www.linkedin.com/company/69257694/">
              <div className="icon-container linkedin">
                <StaticImage
                  width={10}
                  height={10}
                  placeholder="none"
                  src="../../../images/linkedin.svg"
                  className="icon"
                  alt="Linkedin"
                ></StaticImage>
              </div>
            </a>
            <a href="https://www.facebook.com/go.dayra">
              <div className="icon-container facebook">
                <StaticImage
                  width={10}
                  height={10}
                  placeholder="none"
                  src="../../../images/facebook.svg"
                  className="icon"
                  alt="Facebook"
                ></StaticImage>
              </div>
            </a>
            <a href="https://www.instagram.com/go.dayra/">
              <div className="icon-container instagram">
                <StaticImage
                  width={10}
                  height={10}
                  placeholder="none"
                  src="../../../images/instagram.svg"
                  className="icon"
                  alt="Instagram"
                ></StaticImage>
              </div>
            </a>
            <a href="https://www.twitter.com/go_dayra/">
              <div className="icon-container twitter">
                <StaticImage
                  width={10}
                  height={10}
                  placeholder="none"
                  src="../../../images/twitter.svg"
                  className="icon"
                  alt="Twitter"
                ></StaticImage>
              </div>
            </a>
          </div>
        </div>
        <StaticImage
          style={{ marginInlineStart: "100px" }}
          width={450}
          height={303}
          placeholder="none"
          src="../../../images/Contact Visual.png"
          alt="A decorative visual representing two intertwining circles."
          className="contact-image"
        ></StaticImage>
      </Section>
    </div>
  )
}
